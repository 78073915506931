




















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
		name: 'toolbarFilters'
    })
    export default class toolbarFilters extends Vue {
        @Prop({
			type: Array,
			required: true,
		})
		readonly callCenters!: Array<string>;
        @Prop({
			type: Array,
			required: true,
		})
		readonly campanias!: Array<string>;
        @Prop({
			type: Array,
			required: true,
		})
		readonly agentes!: Array<string>;

        @Prop({
			type: Array,
			required: true,
		})
		readonly clientes!: Array<string>;
        @Prop({
			type: String,
			required: true,
		})
		readonly selectedFechaInicial!: string;
        @Prop({
			type: String,
			required: true,
		})
		readonly selectedFechaFinal!: string;
        @Prop({
			type: String,
			required: true,
		})
		readonly selectedCallCenter!: string;
        @Prop({
			type: String,
			required: true,
		})
		readonly selectedCampania!: string;
        @Prop({
			type: String,
			required: true,
		})
		readonly selectedAgente!: string;

        @Prop({
			type: String,
			required: true,
		})
		readonly selectedCliente!: string;
        readonly maxDate: string = new Date()
			.toISOString()
			.slice(0, 10);
	
        public isDateTo = false;
        public dateTo = '';
        public dateEnd = '';
        public isDateEnd = false;
        public dialog_busqueda = false;
        public callCenter = "Todos";
        public campania = "Todos";
        public agente = "Todos";

        public cliente = "Todos";

        public destinatario = "";
        //textos
        public titleFilters = this.$t("toolbarFilters.titleFilters");
        public subTitleFilters = this.$t("toolbarFilters.subTitleFilters");
        public calendarDateTo = this.$t("toolbarFilters.calendarDateTo");
        public calendarDateEnd = this.$t("toolbarFilters.calendarDateEnd");
        public callCenterText = this.$t("toolbarFilters.callCenterText");
        public campaniasText = this.$t("toolbarFilters.campaniasText");
        public agenteText = this.$t("toolbarFilters.agenteText");

        public clientText = this.$t("toolbarFilters.clientText");

        public searchText = this.$t("toolbarFilters.searchText");
        public ingreseText = this.$t("toolbarFilters.ingreseText");
        public btnSearch = this.$t("toolbarFilters.btnSearch");
        public btnCerrar = this.$t("toolbarFilters.btnCerrar");


        mounted() {
            this.dateTo = this.selectedFechaInicial;
            this.dateEnd = this.selectedFechaFinal;
            this.callCenter = this.selectedCallCenter;
            this.campania = this.selectedCampania;
            this.agente = this.selectedAgente;
            this.cliente=this.selectedCliente;
        }

        public cambioVariable(variable: string) {
            let valor = "";
            switch (variable) {
                case 'callCenter':
                    valor = this.callCenter;
                    break;
                case 'campania':
                    valor = this.campania;
                    break;
                case 'agente':
                    valor = this.agente;
                    break;
                case 'dateTo':
                    valor = this.dateTo;
                    break;
                case 'dateEnd':
                    valor = this.dateEnd;
                    break;
            }
            this.$emit("cambio", {"tipo": variable, "valor": valor});
        }

        public buscarDestinatario() {
            if (this.destinatario.includes("-")) {
                this.$router.push({
                    name: 'detailLibrary',
                    params: {
                        destinatario: "true",
                        fechaInicial: this.selectedFechaInicial,
                        fechaFinal: this.selectedFechaFinal,
                        tipo: "rut", 
                        valor: this.destinatario, 
                    }
                });
                //this.$emit("buscar", {"tipo": "rut", "valor": this.destinatario});
            } else if (this.destinatario != "") {
                this.$router.push({
                    name: 'detailLibrary',
                    params: {
                        destinatario: "true",
                        fechaInicial: this.selectedFechaInicial,
                        fechaFinal: this.selectedFechaFinal,
                        tipo: "nombre", 
                        valor: this.destinatario, 
                    }
                });
                //this.$emit("buscar", {"tipo": "nombre", "valor": this.destinatario});
            }
            this.destinatario = "";
            this.dialog_busqueda = false;
        }

    }
